import React, { useEffect, useState } from 'react';
import { Button, Container, Form, Modal, Alert } from 'react-bootstrap';
import axios from 'axios';
import './QuizPage.css'; // Đảm bảo file CSS vẫn phù hợp

const QuizPage = () => {
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [questionCounts, setQuestionCounts] = useState({});
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [isFinished, setIsFinished] = useState(false);
    const [userAnswers, setUserAnswers] = useState([]);
    const [isQuizStarted, setIsQuizStarted] = useState(false);
    const [isAnswered, setIsAnswered] = useState(false);
    const [quizStartTime, setQuizStartTime] = useState(null);
    const [alertMessage, setAlertMessage] = useState('');

    const API_BASE_URL = 'http://agriquiz.vn';


    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/categories`);
                const categoriesWithCount = await Promise.all(response.data.map(async (category) => {
                    const countRes = await axios.get(`${API_BASE_URL}/api/questions/count?category_id=${category.id}`);
                    return { ...category, questionCount: countRes.data.count };
                }));
                setCategories(categoriesWithCount);
            } catch (error) {
                setAlertMessage('Lỗi khi lấy danh mục');
            }
        };
        fetchCategories();
    }, [API_BASE_URL]);

    const handleCategoryChange = (categoryId) => {
        setSelectedCategories((prev) =>
            prev.includes(categoryId) ? prev.filter((id) => id !== categoryId) : [...prev, categoryId]
        );
    };

    const handleQuestionCountChange = (categoryId, count) => {
        const category = categories.find(cat => cat.id === categoryId);

        if (count > category.questionCount) {
            setAlertMessage(`Danh mục ${category.name} chỉ có ${category.questionCount} câu hỏi có sẵn`);
            return;
        }

        const newTotal = totalQuestions - (questionCounts[categoryId] || 0) + count;

        if (newTotal > 100) {
            setAlertMessage('Tổng số lượng câu hỏi không được vượt quá 100');
            return;
        }

        setQuestionCounts((prev) => ({
            ...prev,
            [categoryId]: count,
        }));

        setTotalQuestions(newTotal);
    };

    const handleStartQuiz = async () => {
        if (totalQuestions === 0) {
            setAlertMessage('Vui lòng chọn ít nhất một câu hỏi');
            return;
        }

        try {
            const allQuestions = [];
            await Promise.all(
                selectedCategories.map(async (categoryId) => {
                    const response = await axios.get(`${API_BASE_URL}/api/questions?category_id=${categoryId}&limit=${questionCounts[categoryId]}`);
                    allQuestions.push(...response.data);
                })
            );

            const shuffledQuestions = shuffleArray(allQuestions);
            setQuestions(shuffledQuestions);
            setCurrentQuestionIndex(0);
            setIsFinished(false);
            setUserAnswers(Array(shuffledQuestions.length).fill(null));
            setIsQuizStarted(true);
            setQuizStartTime(Date.now());
            setIsAnswered(false);
        } catch (error) {
            setAlertMessage('Lỗi khi bắt đầu quiz');
        }
    };

    const handleAnswer = (answer) => {
        const updatedAnswers = [...userAnswers];
        updatedAnswers[currentQuestionIndex] = answer;
        setUserAnswers(updatedAnswers);
        setIsAnswered(true);
    };

    const handlePrevious = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
            setIsAnswered(userAnswers[currentQuestionIndex - 1] !== null);
        }
    };

    const handleNext = () => {
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            setIsAnswered(userAnswers[currentQuestionIndex + 1] !== null);
        }
    };

    const handleFinishQuiz = () => {
        setIsFinished(true);
        setIsQuizStarted(false);
    };

    const handleResetQuiz = () => {
        setIsQuizStarted(false);
        setIsFinished(false);
        setQuestions([]);
        setSelectedCategories([]);
        setTotalQuestions(0);
        setQuestionCounts({});
        setCurrentQuestionIndex(0);
    };

    const renderQuestionStatus = () => {
        return (
            <Form.Select
                value={`Câu ${currentQuestionIndex + 1}`}
                onChange={(e) => setCurrentQuestionIndex(Number(e.target.value) - 1)}
                className="w-auto"
            >
                {questions.map((_, index) => (
                    <option key={index} value={index + 1} style={{ color: userAnswers[index] === null ? 'red' : 'black' }}>
                        {`Câu ${index + 1}`}
                    </option>
                ))}
            </Form.Select>
        );
    };

    const renderAnswers = () => {
        const question = questions[currentQuestionIndex];
        const shuffledAnswers = question?.shuffle_answers
            ? shuffleArray([
                { key: '1', text: question.answer1 },
                { key: '2', text: question.answer2 },
                question.answer3 && { key: '3', text: question.answer3 },
                question.answer4 && { key: '4', text: question.answer4 },
              ].filter(Boolean))
            : [
                { key: '1', text: question?.answer1 },
                { key: '2', text: question?.answer2 },
                question?.answer3 && { key: '3', text: question?.answer3 },
                question?.answer4 && { key: '4', text: question?.answer4 },
              ].filter(Boolean);

        return shuffledAnswers.map((answer) => (
            <Button
                key={answer.key}
                className={`answer-button ${userAnswers[currentQuestionIndex] === answer.key ? 'selected-answer' : ''}`}
                onClick={() => handleAnswer(answer.key)}
            >
                {answer.text}
            </Button>
        ));
    };

    return (
        <Container className="quiz-page">
            <header id="quiz-header">
    Agriquiz!
</header>


            <main className="content">
                {alertMessage && <Alert variant="warning">{alertMessage}</Alert>}

                {!isQuizStarted && !isFinished && (
                    <div>
                        <p>Chọn danh mục và số lượng câu hỏi</p>
                        {categories.length > 0 ? (
                            categories.map((category) => (
                                <div key={category.id} className="category">
                                    <span className="category-label">{category.name} ({category.questionCount} câu) </span>
                                    <input
                                        type="checkbox"
                                        checked={selectedCategories.includes(category.id)}
                                        onChange={() => handleCategoryChange(category.id)}
                                    />
                                    {selectedCategories.includes(category.id) && (
                                        <Form.Control
                                            type="number"
                                            min={1}
                                            max={category.questionCount}
                                            placeholder={`Số lượng câu hỏi từ ${category.name}`}
                                            onChange={(e) => handleQuestionCountChange(category.id, Number(e.target.value))}
                                            className="mt-2 question-count-input"
                                        />
                                    )}
                                </div>
                            ))
                        ) : (
                            <p>Đang tải danh mục...</p>
                        )}

                        <Button variant="primary" onClick={handleStartQuiz} disabled={totalQuestions === 0} className="mt-3">
                            Bắt đầu quiz
                        </Button>
                        <Button variant="secondary" onClick={handleResetQuiz} className="mt-3 ms-2">
                            Làm mới
                        </Button>
                    </div>
                )}

                {isQuizStarted && !isFinished && (
                    <>
                        {questions.length > 0 && (
                            <div className="question-container">
                                <h3>Câu {currentQuestionIndex + 1}: {questions[currentQuestionIndex]?.question}</h3>
                                <div className="answers-container">
                                    {renderAnswers()}
                                </div>
                            </div>
                        )}

                        <div className="footer-navigation mt-4">
                            <Button onClick={handlePrevious} disabled={currentQuestionIndex === 0}>
                                Trước
                            </Button>
                            <span>{`${currentQuestionIndex + 1} / ${questions.length}`}</span>
                            <Button onClick={handleNext}>
                                Sau
                            </Button>
                            {renderQuestionStatus()}
                            <Button onClick={handleFinishQuiz} variant="success">
                                Nộp bài
                            </Button>
                        </div>
                    </>
                )}

                {isFinished && (
    <div className="review-answers">
    <h2>Kết quả bài làm</h2>
    <p>
        Bạn đã trả lời đúng {userAnswers.filter((ans, index) => ans === questions[index]?.correct_answer).length}/{questions.length} câu hỏi.
    </p>
    <p>
        Thời gian làm bài: {Math.floor((Date.now() - quizStartTime) / 60000)} phút {Math.floor(((Date.now() - quizStartTime) % 60000) / 1000)} giây.
    </p>

    {questions.map((question, index) => (
        <div key={index} className="review-question">
            <h3>Câu {index + 1}: {question?.question}</h3>

            <p>
                <strong>Đáp án đúng: </strong> 
                <span className="correct-answer">
                    {question[`answer${question?.correct_answer}`] || "Không có thông tin đáp án"}
                </span>
            </p>

            <p className="explanation">
                <strong>Giải thích: </strong> 
                {question?.explanation || "Không có giải thích"}
            </p>

            <p>
                <strong>Bạn đã chọn: </strong> 
                <span className={`user-answer ${userAnswers[index] === question?.correct_answer ? "correct" : "incorrect"}`}>
                    {userAnswers[index] 
                        ? question[`answer${userAnswers[index]}`] 
                        : "Bạn không chọn đáp án"}
                </span>
            </p>

            <hr />
        </div>
    ))}

    {/* Nút Làm lại sẽ luôn nổi */}
    <Button id="retry-btn" onClick={handleResetQuiz} className="floating-retry-button">Làm lại</Button>
</div>

)}

            </main>
        </Container>
    );
};

export default QuizPage;

import React, { useState } from 'react';
import axios from 'axios';

const AddCategoryForm = () => {
  const [categoryName, setCategoryName] = useState('');
  const [categoryDescription, setCategoryDescription] = useState('');
  const [alert, setAlert] = useState(null);

  // Lấy URL API từ biến môi trường
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  // Xử lý thêm danh mục
  const handleAddCategory = async () => {
    try {
      await axios.post(`${apiBaseUrl}/api/add-category`, {
        name: categoryName,
        description: categoryDescription,
      });
      setAlert({ type: 'success', message: 'Chuyên mục đã được thêm thành công' });
      setCategoryName('');
      setCategoryDescription('');
    } catch (error) {
      setAlert({ type: 'danger', message: 'Có lỗi xảy ra khi thêm chuyên mục' });
    }
  };

  return (
    <div className="container mt-4" style={{ maxWidth: '400px', margin: '0 auto' }}>
      {alert && (
        <div className={`alert alert-${alert.type}`} role="alert">
          {alert.message}
        </div>
      )}
      <form>
        <div className="form-group">
          <label htmlFor="categoryName">Tên danh mục</label>
          <input
            type="text"
            className="form-control"
            id="categoryName"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="categoryDescription">Mô tả danh mục</label>
          <textarea
            className="form-control"
            id="categoryDescription"
            rows="3"
            value={categoryDescription}
            onChange={(e) => setCategoryDescription(e.target.value)}
          ></textarea>
        </div>
        <button type="button" className="btn btn-primary" onClick={handleAddCategory}>
          Thêm danh mục
        </button>
      </form>
    </div>
  );
};

export default AddCategoryForm;

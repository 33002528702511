import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Alert } from 'react-bootstrap';
import axios from 'axios';

const QuestionList = () => {
  const [questions, setQuestions] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingQuestion, setEditingQuestion] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [alertMessage, setAlertMessage] = useState({ type: '', message: '' });

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

  // Lấy danh sách câu hỏi từ API
  const fetchQuestions = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/admin/questions`);
      if (Array.isArray(response.data)) {
        setQuestions(response.data);
      } else {
        setAlertMessage({ type: 'danger', message: 'Dữ liệu không hợp lệ' });
      }
    } catch (error) {
      setAlertMessage({ type: 'danger', message: 'Lỗi khi lấy câu hỏi' });
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  useEffect(() => {
    const result = questions.filter(question =>
      question.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
      question.correct_answer.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredQuestions(result);
  }, [searchTerm, questions]);

  // Xoá câu hỏi
  const handleDelete = async (id) => {
    if (window.confirm('Bạn có chắc chắn muốn xoá?')) {
      try {
        await axios.delete(`${API_BASE_URL}/api/delete-question/${id}`);
        setAlertMessage({ type: 'success', message: 'Xoá câu hỏi thành công' });
        fetchQuestions();  // Cập nhật danh sách
      } catch (error) {
        setAlertMessage({ type: 'danger', message: 'Có lỗi khi xoá câu hỏi' });
      }
    }
  };

  // Sửa câu hỏi
  const handleEdit = (question) => {
    setIsEditing(true);
    setEditingQuestion(question);
  };

  const handleSaveEdit = async () => {
    try {
      await axios.put(`${API_BASE_URL}/api/update-question/${editingQuestion.id}`, {
        question: editingQuestion.question,
        answer1: editingQuestion.answer1,
        answer2: editingQuestion.answer2,
        answer3: editingQuestion.answer3,
        answer4: editingQuestion.answer4,
        correct_answer: editingQuestion.correct_answer,
        shuffle_answers: editingQuestion.shuffle_answers,
        category_id: editingQuestion.category_id, // Thêm trường category_id nếu cần
      });
      setAlertMessage({ type: 'success', message: 'Cập nhật câu hỏi thành công' });
      setIsEditing(false);
      fetchQuestions();  // Cập nhật danh sách
    } catch (error) {
      setAlertMessage({ type: 'danger', message: 'Có lỗi khi cập nhật câu hỏi' });
    }
  };

  return (
    <div>
      {alertMessage.message && (
        <Alert variant={alertMessage.type}>{alertMessage.message}</Alert>
      )}

      <Form.Control
        type="text"
        placeholder="Tìm kiếm theo câu hỏi hoặc đáp án..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-3"
      />

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Câu hỏi</th>
            <th>Đáp án đúng</th>
            <th>Danh mục</th>
            <th>Hành động</th>
          </tr>
        </thead>
        <tbody>
          {filteredQuestions.map((question) => (
            <tr key={question.id}>
              <td>{question.question}</td>
              <td>{question.correct_answer}</td>
              <td>{question.category_name}</td>
              <td>
                <Button variant="primary" className="mr-2" onClick={() => handleEdit(question)}>
                  Sửa
                </Button>
                <Button variant="danger" onClick={() => handleDelete(question.id)}>
                  Xoá
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal để sửa câu hỏi */}
      {isEditing && (
        <Modal show={isEditing} onHide={() => setIsEditing(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Sửa câu hỏi</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Câu hỏi</Form.Label>
                <Form.Control
                  type="text"
                  value={editingQuestion?.question}
                  onChange={(e) => setEditingQuestion({ ...editingQuestion, question: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Đáp án 1</Form.Label>
                <Form.Control
                  type="text"
                  value={editingQuestion?.answer1}
                  onChange={(e) => setEditingQuestion({ ...editingQuestion, answer1: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Đáp án 2</Form.Label>
                <Form.Control
                  type="text"
                  value={editingQuestion?.answer2}
                  onChange={(e) => setEditingQuestion({ ...editingQuestion, answer2: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Đáp án 3</Form.Label>
                <Form.Control
                  type="text"
                  value={editingQuestion?.answer3}
                  onChange={(e) => setEditingQuestion({ ...editingQuestion, answer3: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Đáp án 4</Form.Label>
                <Form.Control
                  type="text"
                  value={editingQuestion?.answer4}
                  onChange={(e) => setEditingQuestion({ ...editingQuestion, answer4: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Đáp án đúng</Form.Label>
                <Form.Control
                  type="text"
                  value={editingQuestion?.correct_answer}
                  onChange={(e) => setEditingQuestion({ ...editingQuestion, correct_answer: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Tuỳ chọn (Đảo đáp án)</Form.Label>
                <Form.Check
                  type="switch"
                  label="Đảo đáp án"
                  checked={editingQuestion?.shuffle_answers === 1}
                  onChange={(e) => setEditingQuestion({ ...editingQuestion, shuffle_answers: e.target.checked ? 1 : 0 })}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setIsEditing(false)}>
              Đóng
            </Button>
            <Button variant="primary" onClick={handleSaveEdit}>
              Lưu thay đổi
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default QuestionList;

import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Toast, ToastContainer } from 'react-bootstrap';
import CategoryList from './CategoryList';
import QuestionList from './QuestionList';
import AddCategoryForm from './AddCategoryForm';
import ImportQuestions from './ImportQuestions';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AdminPage = () => {
  const [visible, setVisible] = useState(false); // Trạng thái Sidebar
  const [selectedMenu, setSelectedMenu] = useState('categories'); // Trạng thái menu đang chọn
  const [isModalVisible, setIsModalVisible] = useState(true); // Trạng thái modal nhập mật khẩu
  const [masterPassword, setMasterPassword] = useState('');
  const navigate = useNavigate(); // Khởi tạo navigate
  const [isAccessGranted, setIsAccessGranted] = useState(false); // Trạng thái cấp quyền truy cập
  const [alert, setAlert] = useState({ show: false, type: '', message: '' }); // Cấu trúc để quản lý thông báo

  // Lấy URL API từ biến môi trường
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const toggleSidebar = () => {
    setVisible(!visible);
  };

  const handleMenuSelect = (key) => {
    setSelectedMenu(key);
    setVisible(false); // Đóng sidebar khi chọn menu
  };

  const renderContent = () => {
    if (!isAccessGranted) {
      return null; // Không hiển thị nội dung nếu chưa được cấp quyền
    }
    switch (selectedMenu) {
      case 'categories':
        return <CategoryList />;
      case 'add-category':
        return <AddCategoryForm />;
      case 'questions':
        return <QuestionList />;
      case 'import':
        return <ImportQuestions />;
      default:
        return <CategoryList />;
    }
  };

  const handleAccessAdmin = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/admin?password=${masterPassword}`);
      if (response.status === 200) {
        setAlert({ show: true, type: 'success', message: 'Access granted' });
        setIsModalVisible(false);
        setIsAccessGranted(true);
        navigate('/admin');
      } else {
        setAlert({ show: true, type: 'danger', message: 'Invalid password or access denied' });
      }
    } catch (error) {
      setAlert({ show: true, type: 'danger', message: 'Invalid password or access denied' });
    }
  };

  useEffect(() => {
    setIsModalVisible(true);
  }, []);

  const hideAlert = () => {
    setAlert({ ...alert, show: false });
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      {/* Header */}
      <header className="bg-danger text-white text-center py-3">
        Quản trị hệ thống - Agriquiz
        <Button variant="light" className="position-absolute" style={{ left: 20 }} onClick={toggleSidebar}>
          <i className="bi bi-list"></i>
        </Button>
      </header>

      {/* Sidebar */}
      <div className={`offcanvas offcanvas-start ${visible ? 'show' : ''}`} tabIndex="-1" style={{ visibility: visible ? 'visible' : 'hidden' }}>
        <div className="offcanvas-header">
          <h5 className="offcanvas-title">Menu</h5>
          <button type="button" className="btn-close" aria-label="Close" onClick={toggleSidebar}></button>
        </div>
        <div className="offcanvas-body">
          <ul className="list-group">
            <li className="list-group-item" onClick={() => handleMenuSelect('categories')}>Quản lý danh mục</li>
            <li className="list-group-item" onClick={() => handleMenuSelect('add-category')}>Thêm danh mục</li>
            <li className="list-group-item" onClick={() => handleMenuSelect('questions')}>Quản lý câu hỏi</li>
            <li className="list-group-item" onClick={() => handleMenuSelect('import')}>Import câu hỏi</li>
          </ul>
        </div>
      </div>

      {/* Nội dung chính */}
      <main className="flex-grow-1 p-4">
        {renderContent()}
      </main>

      {/* Footer */}
      <footer className="bg-danger text-white text-center py-3 mt-auto">
        Agriquiz 2024
      </footer>

      {/* Modal nhập mật khẩu */}
      <Modal show={isModalVisible} onHide={() => setIsModalVisible(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Nhập mật khẩu master</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="masterPassword">
              <Form.Label>Mật khẩu:</Form.Label>
              <Form.Control
                type="password"
                placeholder="Nhập mật khẩu master"
                value={masterPassword}
                onChange={(e) => setMasterPassword(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsModalVisible(false)}>Đóng</Button>
          <Button variant="primary" onClick={handleAccessAdmin}>Xác nhận</Button>
        </Modal.Footer>
      </Modal>

      {/* Toast thông báo */}
      <ToastContainer position="top-center" className="p-3">
        <Toast show={alert.show} bg={alert.type} onClose={hideAlert} delay={3000} autohide>
          <Toast.Header>
            <strong className="me-auto">Thông báo</strong>
          </Toast.Header>
          <Toast.Body>{alert.message}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default AdminPage;

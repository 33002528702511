import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './MainPage';
import QuizPage from './QuizPage';
import InfoPage from './InfoPage';
import AdminPage from './AdminPage';


const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<MainPage />} /> {/* Trang chính */}
                <Route path="/quiz" element={<QuizPage />} /> {/* Trang ôn tập quiz */}
                <Route path="/info" element={<InfoPage />} /> {/* Trang giới thiệu */}
                <Route path="/admin" element={<AdminPage />} /> {/* Trang quản trị */}
               
            </Routes>
        </Router>
    );
};

export default App;

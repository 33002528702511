import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Alert } from 'react-bootstrap';
import axios from 'axios';

const CategoryList = () => {
  const [categories, setCategories] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);
  const [alert, setAlert] = useState(null);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

  // Lấy danh sách danh mục từ API
  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/categories`);
      if (Array.isArray(response.data)) {
        setCategories(response.data);
      } else {
        setAlert({ type: 'danger', message: 'Dữ liệu trả về không hợp lệ' });
        setCategories([]);
      }
    } catch (error) {
      setAlert({ type: 'danger', message: 'Có lỗi khi lấy danh mục' });
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  // Xoá danh mục
  const handleDelete = async (id) => {
    if (window.confirm("Bạn có chắc chắn muốn xoá?")) {
      try {
        await axios.delete(`${API_BASE_URL}/api/delete-category/${id}`);
        setAlert({ type: 'success', message: 'Xoá danh mục thành công' });
        fetchCategories();  // Cập nhật danh sách
      } catch (error) {
        setAlert({ type: 'danger', message: 'Có lỗi khi xoá danh mục' });
      }
    }
  };

  // Sửa danh mục
  const handleEdit = (category) => {
    setIsEditing(true);
    setEditingCategory(category);
  };

  const handleSaveEdit = async () => {
    try {
      await axios.put(`${API_BASE_URL}/api/update-category/${editingCategory.id}`, {
        name: editingCategory.name,
        description: editingCategory.description,
      });
      setAlert({ type: 'success', message: 'Cập nhật danh mục thành công' });
      setIsEditing(false);
      fetchCategories();  // Cập nhật danh sách
    } catch (error) {
      setAlert({ type: 'danger', message: 'Có lỗi khi cập nhật danh mục' });
    }
  };

  const columns = [
    { title: 'Tên danh mục', dataField: 'name' },
    { title: 'Mô tả', dataField: 'description' },
    {
      title: 'Hành động',
      formatter: (row) => (
        <>
          <Button variant="primary" className="mr-2" onClick={() => handleEdit(row)}>Sửa</Button>
          <Button variant="danger" onClick={() => handleDelete(row.id)}>Xoá</Button>
        </>
      ),
    },
  ];

  return (
    <div>
      {alert && <Alert variant={alert.type}>{alert.message}</Alert>}

      <Table striped bordered hover>
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th key={index}>{col.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <tr key={category.id}>
              <td>{category.name}</td>
              <td>{category.description}</td>
              <td>
                <Button variant="primary" className="mr-2" onClick={() => handleEdit(category)}>
                  Sửa
                </Button>
                <Button variant="danger" onClick={() => handleDelete(category.id)}>
                  Xoá
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal để sửa danh mục */}
      {isEditing && editingCategory && (
        <Modal show={isEditing} onHide={() => setIsEditing(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Sửa danh mục</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formCategoryName">
                <Form.Label>Tên danh mục</Form.Label>
                <Form.Control
                  type="text"
                  value={editingCategory?.name || ''}
                  onChange={(e) => setEditingCategory({ ...editingCategory, name: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="formCategoryDescription">
                <Form.Label>Mô tả</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={editingCategory?.description || ''}
                  onChange={(e) => setEditingCategory({ ...editingCategory, description: e.target.value })}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setIsEditing(false)}>
              Đóng
            </Button>
            <Button variant="primary" onClick={handleSaveEdit}>
              Lưu thay đổi
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default CategoryList;

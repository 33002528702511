import React, { useState, useEffect } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';

const ImportQuestions = () => {
  const [file, setFile] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [importStatus, setImportStatus] = useState(null);
  const [alert, setAlert] = useState(null);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

  // Lấy danh sách chuyên mục từ API
  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/categories`);
      setCategories(response.data);
    } catch (error) {
      setAlert({ type: 'danger', message: 'Có lỗi khi lấy danh mục' });
    }
  };

  useEffect(() => {
    fetchCategories();  // Gọi khi component được render
  }, []);

  // Xử lý upload file Excel
  const handleUpload = async () => {
    if (!file || !selectedCategory) {
      setAlert({ type: 'danger', message: 'Vui lòng chọn file và danh mục' });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('category_id', selectedCategory);  // Gửi kèm ID danh mục

    try {
      const response = await axios.post(`${API_BASE_URL}/api/upload-questions`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setImportStatus(response.data.importStatus); // Lưu trữ trạng thái import
      setAlert({ type: 'success', message: 'File đã được upload thành công' });
      setFile(null);
      setSelectedCategory(null); // Đặt lại danh mục đã chọn
    } catch (error) {
      setAlert({ type: 'danger', message: 'Có lỗi xảy ra khi upload file' });
    }
  };

  return (
    <div style={{ maxWidth: '400px', margin: '0 auto' }}>
      {alert && <Alert variant={alert.type}>{alert.message}</Alert>}

      <Form.Group controlId="categorySelect">
        <Form.Label>Chọn danh mục</Form.Label>
        <Form.Control
          as="select"
          value={selectedCategory || ''}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value="" disabled>Chọn danh mục</option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Form.Group controlId="fileUpload" className="mt-3">
        <Form.Label>Chọn file</Form.Label>
        <Form.Control
          type="file"
          onChange={(e) => setFile(e.target.files[0])}
          accept=".xlsx"
        />
      </Form.Group>

      <Button variant="primary" onClick={handleUpload} className="mt-3">
        Upload câu hỏi
      </Button>

      {importStatus && (
        <div className="mt-3">
          <h4>Kết quả import:</h4>
          <p>Tổng số câu hỏi: {importStatus.total}</p>
          <p>Số câu hỏi đã cập nhật: {importStatus.updated}</p>
          <p>Số câu hỏi đã thêm mới: {importStatus.added}</p>
        </div>
      )}
    </div>
  );
};

export default ImportQuestions;

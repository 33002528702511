import React, { useState } from 'react';
import { Button, Container, Row, Col, Modal, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const PasswordModal = ({ setIsPasswordCorrect }) => {
  const [password, setPassword] = useState('');
  
  const handlePasswordSubmit = () => {
    if (password === 'correct_password') {
      setIsPasswordCorrect(true);
    } else {
      alert('Sai mật khẩu');
    }
  };

  return (
    <Modal show onHide={() => setIsPasswordCorrect(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Nhập mật khẩu</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Mật khẩu</Form.Label>
          <Form.Control
            type="password"
            placeholder="Nhập mật khẩu"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setIsPasswordCorrect(false)}>
          Đóng
        </Button>
        <Button variant="primary" onClick={handlePasswordSubmit}>
          Xác nhận
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const MainPage = () => {
  const navigate = useNavigate();
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);

  return (
    <div className="d-flex flex-column min-vh-100">
      {/* Header */}
      <header className="bg-danger text-white text-center py-3">
        <h3 className="m-0">Agriquiz!</h3>
      </header>

      {/* Content */}
      <Container fluid className="flex-grow-1 text-center py-4">
        <p className="text-muted" style={{ fontSize: '18px', marginBottom: '30px' }}>
          Xin chào! Chúc mọi người ôn tập tốt!
        </p>
        <Row className="justify-content-center">
          <Col xs={12} md={6}>
            <Button
              variant="primary"
              className="m-2"
              style={{ width: '220px', fontSize: '16px', borderRadius: '8px', padding: '10px 0' }}
              onClick={() => navigate('/quiz')}
            >
              Ôn tập nghiệp vụ
            </Button>
          </Col>
          <Col xs={12} md={6}>
            <Button
              variant="secondary"
              className="m-2"
              style={{ width: '220px', fontSize: '16px', borderRadius: '8px', padding: '10px 0' }}
              onClick={() => navigate('/info')}
            >
              Giới thiệu
            </Button>
          </Col>
        </Row>
      </Container>

      {/* Modal nhập mật khẩu */}
      {isPasswordCorrect && <PasswordModal setIsPasswordCorrect={setIsPasswordCorrect} />}

      {/* Footer */}
      <footer className="bg-danger text-white text-center py-3 mt-auto">
        <p className="mb-0" style={{ fontSize: '14px' }}>
          Agriquiz 2024
        </p>
      </footer>
    </div>
  );
};

export default MainPage;

import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const InfoPage = () => {
  const navigate = useNavigate();

  return (
    <div className="d-flex flex-column min-vh-100">
      {/* Header */}
      <header className="bg-danger text-white text-center py-3">
        <h1>Agriquiz!</h1>
      </header>

      {/* Content */}
      <Container fluid className="flex-grow-1">
        <Row className="justify-content-center">
          <Col xs={12} md={8} lg={6} className="text-center">
            <h3>Thông tin về ứng dụng</h3>
            <p>Đây là hệ thống thi trắc nghiệm online, giúp bạn luyện tập nghiệp vụ nhanh chóng</p>
            <p>Để duy trì hoạt động ứng dụng bạn có thể donate cho tác giả quá TK Agribank 3611 205 088888 - Phan Tiến</p>
            <p>Mọi đóng góp ý kiến về câu hỏi xin gửi qua Zalo hoặc email 0945626999 Email: taoco3bich@gmail.com</p>
            <p>Cảm ơn bạn đã sử dụng ứng dụng 83 86 chúc bạn thi tốt!</p>
            <Button
              variant="primary"
              className="mt-3"
              style={{ width: '200px', fontSize: '18px', borderRadius: '8px' }}
              onClick={() => navigate('/')}
            >
              Trở về trang chính
            </Button>
          </Col>
        </Row>
      </Container>

      {/* Footer */}
      <footer className="bg-danger text-white text-center py-3 mt-auto">
        Agriquiz 2024
      </footer>
    </div>
  );
};

export default InfoPage;
